<template>
  <div class="">
    <div class="">
      <section class="content">
        <div class="container-fluid">
            <div class="row">
                <section class="col-lg-12 connectedSortable mt-3 pr-0 pl-0 mb-3">
                    <div class="card">
                        <div class="card-body table-responsive table-head">
                            <div class="row bor-bot">
                                <div class="col-md-8 pl-0 pb-3">
                                    <h1 class="m-0 text-dark pt-2 pb-1">Financials Summary</h1>
                                </div>
                                <div class="col-md-4">
                                    <div class="text-right pb-3">
                                        <ol class="breadcrumb float-sm-right">
                                            <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                                            <li class="breadcrumb-item"><Breadcrumbs/></li>
                                        </ol>
                                    </div> 
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group pt-3">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <div class="custom-control custom-radio">
                                                    <input type="radio" id="by_day" name="filter_type" class="custom-control-input" v-model="filter_type" value="day" @change="triggerFilter">
                                                    <label class="custom-control-label" for="by_day">By Day</label>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <select name="by_day_filter" id="by_day_filter" class="form-control" v-model="by_day_filter" :style="{visibility: filter_type == 'day' ? 'visible' : 'hidden'}"  @change="triggerFilter">
                                                        <option value="today">Today</option>
                                                        <option value="yesterday">Yesterday</option>
                                                        <option value="day_range">Date Range</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" v-if="filter_type == 'day' && by_day_filter == 'day_range'">
                                            <div class="col-md-4 offset-md-3">
                                                <div class="form-group">
                                                    <label class="form-label">From Date</label>
                                                    <div class="input-group birthdate">
                                                        <div class="birthdaypicker">
                                                            <date-pick
                                                                v-model="date_range.from"
                                                                :format="'MM/DD/YYYY'"
                                                                :displayFormat="'MM/DD/YYYY'"
                                                                v-mask="'##/##/####'"
                                                                @input="triggerFilter"
                                                                :parseDate="parseDatePick"
                                                            ></date-pick>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label class="form-label">To Date</label>
                                                    <div class="input-group birthdate">
                                                        <div class="birthdaypicker">
                                                            <date-pick
                                                                v-model="date_range.to"
                                                                :format="'MM/DD/YYYY'"
                                                                :displayFormat="'MM/DD/YYYY'"
                                                                v-mask="'##/##/####'"
                                                                @input="triggerFilter"
                                                                :parseDate="parseDatePick"
                                                            ></date-pick>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-3">
                                                <div class="custom-control custom-radio">
                                                    <input type="radio" id="by_monthly" name="filter_type" class="custom-control-input" v-model="filter_type" value="monthly" @change="triggerFilter">
                                                    <label class="custom-control-label" for="by_monthly">By Monthly</label>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <select name="by_monthly_filter" id="by_monthly_filter" class="form-control" v-model="by_monthly_filter" :style="{visibility: filter_type == 'monthly' ? 'visible' : 'hidden'}"  @change="triggerFilter">
                                                        <option value="Jan">January</option>
                                                        <option value="Feb">February</option>
                                                        <option value="Mar">March</option>
                                                        <option value="Apr">April</option>
                                                        <option value="May">May</option>
                                                        <option value="Jun">June</option>
                                                        <option value="Jul">July</option>
                                                        <option value="Aug">August</option>
                                                        <option value="Sep">September</option>
                                                        <option value="Oct">October</option>
                                                        <option value="Nov">November</option>
                                                        <option value="Dec">December</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                              <div class="form-group">
                                                <select name="by_yearly_filter" id="by_monthly_filter_year" class="form-control" v-model="by_monthly_filter_year" :style="{visibility: filter_type == 'monthly' ? 'visible' : 'hidden'}" @change="triggerFilter">
                                                  <template v-for="year in years">
                                                    <option v-if="year >= 2018" :value="year" :key="'year' + year">{{ year }}</option>
                                                  </template>
                                                </select>
                                              </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-3">
                                                <div class="custom-control custom-radio">
                                                    <input type="radio" id="by_quarterly" name="filter_type" class="custom-control-input" v-model="filter_type" value="quarterly" @change="triggerFilter">
                                                    <label class="custom-control-label" for="by_quarterly">By Quarterly</label>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <select name="by_quarterly_filter" id="by_quarterly_filter" class="form-control" v-model="by_quarterly_filter" :style="{visibility: filter_type == 'quarterly' ? 'visible' : 'hidden'}" @change="triggerFilter">
                                                        <option>Q1</option>
                                                        <option>Q2</option>
                                                        <option>Q3</option>
                                                        <option>Q4</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-3">
                                                <div class="custom-control custom-radio">
                                                    <input type="radio" id="by_yearly" name="filter_type" class="custom-control-input" v-model="filter_type" value="yearly" @change="triggerFilter">
                                                    <label class="custom-control-label" for="by_yearly">By Yearly</label>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <select name="by_yearly_filter" id="by_yearly_filter" class="form-control" v-model="by_yearly_filter" :style="{visibility: filter_type == 'yearly' ? 'visible' : 'hidden'}" @change="triggerFilter">
                                                        <template v-for="year in years">
                                                            <option v-if="year >= 2018" :value="year" :key="'year' + year">{{ year }}</option>
                                                        </template>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <button type="button" class="btn btn-primary darkblubtn" @click="triggerCSVExport"><i class="fas fa-download"></i> Download as CSV</button>
                                    </div>
                                </div>
                                <div class="col-md-5 pt-3">
                                    <div class="table-responsive">
                                        <table class="table table-striped table-hover" id="financial-summary-list">
                                            <thead>
                                                <tr>
                                                    <th>Acct Code / Class</th>
                                                    <th>Category</th>
                                                    <th>Total Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <!-- <tr>
                                                    <td>2061/3012</td>
                                                    <td>Membership Active</td>
                                                    <td>$ 25000</td>
                                                </tr>
                                                <tr>
                                                    <td>3020</td>
                                                    <td>Membership Supporting</td>
                                                    <td>$ 15000</td>
                                                </tr>
                                                <tr>
                                                    <td>3034</td>
                                                    <td>Membership Club</td>
                                                    <td>$ 34000</td>
                                                </tr>
                                                <tr>
                                                    <td>3036</td>
                                                    <td>Camps/Ski Schools</td>
                                                    <td>$ 34000</td>
                                                </tr>
                                                <tr>
                                                    <td>3040</td>
                                                    <td>Technology / Processing Fee</td>
                                                    <td>$ 1000</td>
                                                </tr>
                                                <tr>
                                                    <td>4018</td>
                                                    <td>Merchandise</td>
                                                    <td>$ 12000</td>
                                                </tr>
                                                <tr>
                                                    <td>2044</td>
                                                    <td>Donation</td>
                                                    <td>$ 12000</td>
                                                </tr> -->
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td></td>
                                                    <td>Net Total:</td>
                                                    <td><strong>$0</strong></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
      </section>
    </div>
  </div>
</template>

<style>
    .financial-summary-csv-export-btn{
        display: none !important;
    }
</style>

<script>
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"

import $ from 'jquery';

import axios from 'axios';
import moment, { months } from 'moment';
export default {
    data() {
        return {
            table: null,
            filter_type: 'monthly',
            by_day_filter: 'today',
            by_monthly_filter: moment(new Date()).subtract(1, "month").format('MMM'),
            by_monthly_filter_year: moment(new Date()).subtract(1, "month").format('YYYY'),
            by_quarterly_filter: 'Q1',
            by_yearly_filter: moment(new Date()).format('YYYY'),
            date_range: {
                from: moment(new Date()).startOf("month").format("MM/DD/YYYY"),
                to: moment(new Date()).endOf("month").format("MM/DD/YYYY"),
            }
        };
    },
    methods: {
        triggerCSVExport: function(event){
            this.table.button( '.financial-summary-csv-export-btn' ).trigger();
        },
        triggerFilter: function(event){
            this.table.draw();
        }
    },
    mounted(){
        var self = this;
        this.table = $("#financial-summary-list").DataTable({
            dom: "<'row mt-2'<'col-sm-12 col-md-4'l><'col-sm-12 col-md-4'B>"+
            "<'col-sm-12 col-md-4'f>><'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
            buttons: [
                {
                    extend: 'csv',
                    text: '<i class="fas fa-file-csv"></i> Export',
                    className: 'btn-primary btn-darkblue btn financial-summary-csv-export-btn',
                    filename:"Financial Summary Report",
                    title: "Financial summary report",
                    exportOptions: {
                        columns: ':visible'
                    },
                    footer: true
                }
            ],
            responsive: true,
            bLengthChange: false,
            bInfo: false,
            searching: false,
            bPaginate: false,
            aaSorting: [],
            processing: true,
            serverSide: true,
            ajax: {
                url: process.env.VUE_APP_URL + "api/report/financial-summary",
                type: 'post',
                data: function(d) {
                    d.filter_type = $('[name=filter_type]:checked').val();
                    d.filter_value = () => {
                        if($('[name=filter_type]:checked').val() == "day") return $('#by_day_filter').val();
                        if($('[name=filter_type]:checked').val() == "monthly") return $('#by_monthly_filter').val();
                        if($('[name=filter_type]:checked').val() == "quarterly") return $('#by_quarterly_filter').val();
                        if($('[name=filter_type]:checked').val() == "yearly") return $('#by_yearly_filter').val();
                    },
                    d.date_range = self.date_range,
                    d.filter_monthly_year = () => {
                      if($('[name=filter_type]:checked').val() == "monthly") return $('#by_monthly_filter_year').val();
                      else return null;
                    }
                }
            },
            columns: [
                {data: "class", name: "class", orderable: true, searchable: true},
                {data: "product", name: "product", orderable: true, searchable: true},
                {data: "cost", name: "cost", orderable: true, searchable: true, render: (cost) => { return '$'+parseFloat(cost).toFixed(2) }},
            ],
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api();

                // Remove the formatting to get integer data for summation
                var intVal = i => {
                    return typeof i === 'string' ? i.substring(1) : typeof i === 'number' ? i : 0;
                };

                // Total over all pages
                var total = api.column( 2 ).data().reduce( function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0 );

                // Update footer
                $( api.column( 2 ).footer() ).html(
                    '<strong>$'+ total.toFixed(2) + '</strong>'
                );
            },
        });

        if(this.subdomain == "shooting"){
            var column = this.table.column(0);
            column.visible(false);
        }
    },
    computed: {
        years () {
            return new Date().getFullYear()
        },
    }
};
</script>